.GuessList {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none; 
  padding: 0;
}

.GuessList li {
  margin-bottom: 5px; 
  font-size: 16px; 
  line-height: 1.5; 
}

.GuessList-container {
  border: 1px solid #fff; 
  border-radius: 5px; 
  padding-left: 10px; 
  padding-right: 10px;
  max-height: 150px; 
  overflow-y: auto; 
}