/* Styling for the overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998; 
}

/* Styling for the popup */
.info-popup {
  color: black;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 9999; 
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-popup h2 {
  font-size: 34px;
  margin-bottom: 0px;
}

.info-popup h3 {
  margin-bottom: 0px;
}

.info-popup p {
  text-align: center;
  font-size: 18px;
  margin-top: 0px;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
}
/*Open button style */
.open-button {
  /* position: fixed;
  top: 10%;
  left: 5%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
  position: absolute;
  top: 5%;
  left: 3%;
  background-color: rgb(32, 33, 36);
  color: #FFFFFF; 
  padding: 10px 20px; 
  border-radius: 5px; 
  font-size: 16px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  border: 1px solid #ffffff; 
  box-sizing: border-box; 
}

/* Hovering on button style*/
.open-button:hover {
  background-color: #CCCC;
}


.info-popup .non-close {
  background-color: white;
  color: black; 
  padding: 5px 10px; 
  border-radius: 5px; 
  font-size: 14px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  border: 1px solid black; 
  box-sizing: border-box; 
  margin-top: 15px;
}

/* .circle-container {
  display: flex;
  align-items: center;
}

.small-box {
  width: 15px;
  height: 15px;
  background-color: #caa4a751;
  border-radius: 50%;
}

.circle-text {
  margin-left: 10px; 
} */


