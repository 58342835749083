.settings-button {
  /* position: fixed;
  top: 10%;
  left: 95%; 
  transform: translate(-50%, -50%);
  padding: 10px;
  background-color: #000000; 
  color: white;
  border: none;
  border-radius: 10%; 
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
  position: absolute;
  top: 5%;
  right: 3%;
  background-color: rgb(32, 33, 36);
  color: #FFFFFF; 
  padding: 10px 20px; 
  border-radius: 5px; 
  font-size: 16px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  border: 1px solid #ffffff; 
  box-sizing: border-box; 
}

.settings-button:hover{
  background-color: #CCCC;
}
  
  /* Styling for the overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998; 
}


/* Styling for the popup */
.info-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 9999; 
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.info-popup .toggle-button {
  width: 100%;
  background-color: white;
  color: black; 
  padding: 5px 10px; 
  border-radius: 5px; 
  font-size: 14px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  border: 1px solid black; 
  box-sizing: border-box;
  margin-top: 5px;
}

  