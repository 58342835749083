.home {
  /* background-color: #202124; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-bottom: 30px;

}

.home h1 {
  font-size: 50px;
}

.submit-button {
  background-color: rgb(32, 33, 36);
  color: #FFFFFF; 
  padding: 10px 20px; 
  border-radius: 5px; 
  font-size: 16px; 
  cursor: pointer; 
  transition: background-color 0.3s ease; 
  border: 1px solid #ffffff; 
  box-sizing: border-box; 
}

.submit-button:hover {
  background-color: #CCCC; 
}
dark-mode {
  background-color: #222; 
  color: #fff;
}

.light-mode {
  background-color: #fff; 
  color: #000; 
}